<template>
    <div class="us-culture">
        <div class="us-culture-title common-title">
            <span class="commm-title-label" />
            <div class="common-title-content">
                <span class="title-content-zh">{{ Zh }}</span>
                <span class="title-content-cn">{{ English }}</span>
            </div>
            <span class="commm-title-label" />
        </div>
    </div>
</template>

<script>
export default {
    name: "pageTitle",
    props: {
        Zh:{
            type:String,
            default:""
        },
        English:{
            type:String,
            default:""
        },
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="less" scoped>
/* 企业文化 */
.us-culture {
    width: 100%;
    display: flex;
    align-content: center;

    .m-t-10 {
        margin-top: -7px !important;
    }

    .us-culture-content {
        display: flex;

        .culture-content-left {
            box-sizing: border-box;

            .content-left-item {
                text-align: left;

                div {
                    display: flex;
                    align-items: center;
                    color: @global-active-text-color;

                    h3 {
                        font-weight: normal;
                        letter-spacing: 2px;
                    }

                    span {
                        display: inline-block;
                        margin-left: 10px;
                        letter-spacing: 1px;
                    }
                }

                p {
                    color: @global-text-color;
                    letter-spacing: 1px;

                    span {
                        display: block;
                    }
                }
            }
        }
    }
}
</style>