<template>
  <div class="culture-container">
    <div class="culture-swiper" :style="'height:' + swiperHeight + 'px'" />
    <div class="us-culture-content ">
      <!--企业愿景和企业使命-->
      <div class="clearfix">
        <div class="left">
          <div class="center">
            <pageTitle Zh="企业愿景" English="Company vision" ref="title1" />
          </div>
          <p class="img">
            <img src="../assets/Images/company/company_01.jpg" alt="">
          </p>
        </div>
        <div class="right">
          <div class="center">
            <pageTitle Zh="企业使命" English="Enterprise mission" ref="title2" />
          </div>
          <p class="img">
            <img src="../assets/Images/company/company_02.jpg" alt="">
          </p>
        </div>
      </div>
      <!--企业精神-->
      <div class="spirit">
        <div class="title">
          <pageTitle Zh="企业精神" English="Spirit of Enterprise" ref="title3" />
        </div>
        <div class="content">
          <p class="imgList" v-for="img in spiritList" :key="img.key">
            <img :src="img.url" />
          </p>
        </div>
      </div>
      <!--企业经营理念-->
      <div class="philosophy">
        <div class="title">
          <pageTitle Zh="企业经营理念" English="Business philosophy" ref="title4" />
        </div>
        <div class="content">
          <div class="list" v-for="img in philosophyList" :key="img.key">
            <p>
              <img :src="img.url" />
            </p>
          </div>
        </div>
      </div>
      <!--企业经营理念-->
      <div class="philosophy">
        <div class="title">
          <pageTitle Zh="企业价值观" English="Corporate Value" ref="title5" />
        </div>
        <div class="content">
          <div class="list" v-for="img in corporateList" :key="img.key">
            <p>
              <img :src="img.url" />
            </p>
          </div>
        </div>
      </div>
      <div class="height30">&ensp;</div>
    </div>
  </div>
</template>
<script>
import pageTitle from '../components/Title/index.vue'
export default {
  data() {
    return {
      swiperHeight: 0,
      clientWidth: 0,
      eventWidth: 0,
    }
  },
  components: { pageTitle },
  computed: {
    spiritList() {
      let arr = ['company_03.jpg', 'company_04.jpg', 'company_05.jpg', 'company_06.jpg'];
      return this.setImgList(arr, 'spri_');
    },
    philosophyList() {
      let arr = ['company_07.jpg', 'company_08.jpg', 'company_09.jpg', 'company_10.jpg', 'company_11.jpg', 'company_12.jpg'];
      return this.setImgList(arr, 'phi_');
    },
    corporateList() {
      let arr = ['company_13.jpg', 'company_14.jpg', 'company_15.jpg', 'company_16.jpg', 'company_17.jpg', 'company_18.jpg'];
      return this.setImgList(arr, 'corp_');
    }
  },
  methods: {
    setImgList(arr, key) {
      let imgArr = [];
      arr.forEach((img, index) => {
        imgArr.push({
          url: require('../assets/Images/company/' + img),
          key: `${key}${index}`
        })
      })
      return imgArr
    }
  },
  mounted() {
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    this.swiperHeight = Math.round(this.clientWidth / 4)
    if (this.clientWidth >= 700) {
      this.eventWidth = 350
    } else {
      this.eventWidth = this.clientWidth / 2 - 40
    }
    window.onresize = () => {
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      this.swiperHeight = Math.round(this.clientWidth / 4)
      if (this.clientWidth >= 700) {
        this.eventWidth = 350
      } else {
        this.eventWidth = this.clientWidth / 2 - 40
      }
    }
  }
}
</script>
<style lang="less">
.culture-swiper {
  width: 100%;
  background-image: url("../assets/Images/culture.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.us-culture-content {
  width: 100%;
  max-width: 1199px;
  margin: 0 auto;
  text-align: center;

  .left {
    width: 50%;
    height: auto;
    float: left;
  }

  .right {
    width: 50%;
    height: auto;
    float: right;
  }

  div.center {
    width: 100%;
    height: auto;
    display: flex;
    align-content: center;
  }

  p.img {
    width: 555px;
    height: 730px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;

    img {
      max-width: 100%;
      transition: all 0.1s linear 0s;

      &:hover {
        cursor: pointer;
        transform: scale(1.03);
      }
    }
  }

  .spirit {
    width: 100%;
    height: auto;

    .title {
      width: 100%;
    }

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .imgList {
        width: 280px;
        height: 413px;
        overflow: hidden;
        text-align: center;

        &+.imgList {
          margin-left: 26px;
        }

        img {

          transition: all 0.1s linear 0s;

          &:hover {
            cursor: pointer;
            transform: scale(1.03);
          }
        }
      }
    }
  }

  .philosophy {
    width: 100%;
    height: auto;

    .title {
      width: 100%;
    }

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .list {
        width: calc(33% - 37px);
        margin: 13px;
        padding: 10px;
        text-align: center;
        background-color: #ffffff;
        border-radius: 8px;
        border: solid 1px #e6e6e6;
        overflow: hidden;
        transition: all 0.2s;
        //box-shadow: 0 10px 20px rgba(48, 49, 51, 0.15);

        &:first-child {
          margin-left: 0
        }

        &:nth-child(4n) {
          margin-left: 0
        }

        &:nth-child(3n) {
          margin-right: 0
        }

        p {
          width: 100%;
          overflow: hidden;
          margin: 0;
          padding: 0;
        }

        img {
          width: 100%;

          &:hover {
            cursor: pointer;
            transform: scale(1.05);
          }
        }

        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }

  .height30 {
    height: 30px;
  }
}

/* -----------适应pc端(大屏幕)--------------- */
@media screen and (min-width: 1160px) {}

/* -----------适应pc端(中屏幕)--------------- */
@media screen and (min-width: 700px) and (max-width: 1160px) {
  .us-culture-content {

    .left,
    .right {
      width: 100%;
      float: none
    }
  }
}

/* -------------适应移动端---------------- */
@media screen and (max-width: 700px) {
  .us-culture-content {

    .left,
    .right {
      width: 100%;
      float: none
    }

    p.img {
      width: 100%;
      height: auto;

      img {
        max-width: 85%;
      }
    }

    .spirit {
      .content {
        .imgList {
          width: 100%;

          &+.imgList {
            margin-left: 0;
          }

          img {
            width: 85%;
          }
        }
      }
    }

    .philosophy {
      .content {
        .list {
          width: 79%;
          margin: 13px auto !important;

          img {
            width: 100%
          }
        }
      }
    }

  }
}
</style>
